<template>
  <Vue3Odometer :format="resFormat" :value="value" :duration="resDuration"></Vue3Odometer>
</template>

<script>
import Vue3Odometer from "vue3-odometer";
export default {
  components: { Vue3Odometer },
  setup(props) {
    const { value, format, duration } = toRefs(props);

    const formatBalance = (e) => {
      //
    };

    const resFormat = format || "(ddd).dd";
    const resDuration = duration || 3000;
    return {
      formatBalance,
      value,
      resFormat,
      resDuration,
    };
  },
};
</script>

<style lang="scss" scoped></style>
